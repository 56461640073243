<template>
  <CRow>
    <CCol col="12" xl="12">
      <transition name="slide">
        <CCard>
          <CCardHeader>
            <div class="d-flex justify-content-between">
              <CButton @click="$router.push('/alertas')">Volver</CButton>

              <p class="mb-0 font-weight-bold" style="font-size: 16px">Alerta</p>
              <div clas="d-flex align-items-center justify-content-center">
<!--                 <CButton
                  color="primary"
                  class="m-1"
                  size="sm"
                  @click=""
                >
                  Terminar Alerta
                </CButton> -->
                <p class="mb-0 font-weight-bold" v-if="alerta">Creada el: {{alerta.created_at}}</p>
              </div>
            </div>
          </CCardHeader>
          <CCardBody>
            <div 
              v-if="loading"
              class="d-flex flex-column justify-content-center align-items-center" style="min-height: 300px;">
              <CSpinner
                style="width:4rem;height:4rem;"
                color="danger"
              />
              <p class="text-secondary my-2">Cargando datos ...</p>
            </div>
            <CRow v-if="alerta">
              <CCol col="12" xl="12">
                <CRow>
                  <CCol col="6" xl="6">
                    <div class="d-flex justify-content-center align-items-center font-weight-bold text-center my-3 py-2 bg-dark text-white">
                      <p class="mb-0 ">Usuario {{alerta.user.name}}</p>
                    </div>
                    <CRow>
                      <CCol col="4" xl="4">             
                        <img
                          style="width: 100%; height: auto;"
                          :src="alerta.user.info.avatar"
                          class="c-mb-2"
                        />
                      </CCol>
                      <CCol col="8" xl="8">
                        <p class="mb-0"><b>Email:</b> {{alerta.user.email}}</p>
                        <p class="mb-0"><b>Teléfono:</b> {{alerta.user.info.telefono}}</p>
                        <p class="mb-0"><b>Dirección:</b> {{alerta.user.info.direccion}}</p>
                      </CCol>
                    </CRow>
                  </CCol>
                  <CCol col="6" xl="6">
                    <div class="d-flex justify-content-center align-items-center font-weight-bold text-center my-3 py-2 bg-dark text-white">
                      <p class="mb-0 ">Posición del usuario al generar la alerta</p>
                    </div>
                    <GmapMap v-if="alerta.datos.geo_usuario" :center="formatPosition(alerta.datos.geo_usuario)" :zoom="12"
                      class="map-container" style="height: 300px" >
                        <GmapMarker
                          :position="formatPosition(alerta.datos.geo_usuario)"
                          :clickable="true"
                          :draggable="true"
                        />
                    </GmapMap>
                  </CCol>
                </CRow>
              </CCol>
              <CCol class="d-flex flex-column justify-content-between align-items-between" col="12" xl="12" v-if="alerta.datos.camera">
                <CRow>
                  <CCol col="6" xl="6"> 
                    <div class="d-flex justify-content-center align-items-center font-weight-bold text-center my-3 py-2 bg-dark text-white">
                      <p class="mb-0 ">Alerta generada en visualización de la siguiente cámara</p>
                    </div>
                    <p class="mb-0"><b>ID:</b> {{alerta.datos.camera.id}}</p>
                    <p class="mb-0"><b>Nombre:</b> {{alerta.datos.camera.name}}</p>
                    <p class="mb-0"><b>Url:</b> {{alerta.datos.camera.url}}</p>
                  </CCol>
                  <CCol col="6" xl="6"> 
                    <div class="d-flex justify-content-center align-items-center font-weight-bold text-center my-3 py-2 bg-dark text-white">
                      <p class="mb-0 ">Ubicación de la Cámara</p>
                    </div>
                    <GmapMap v-if="alerta.datos.camera.latitude && alerta.datos.camera.longitude" :center="formatPosition(alerta.datos.camera)" :zoom="12"
                      class="map-container" style="height: 300px" >
                        <GmapMarker
                          :position="formatPosition(alerta.datos.camera)"
                          :clickable="true"
                          :draggable="true"
                        />
                    </GmapMap>
                  </CCol>
                </CRow>
              </CCol>
            </CRow>
            <CRow v-if="alerta">
              <CCol col="12" sm="6">
                <div class="d-flex justify-content-center align-items-center font-weight-bold text-center my-3 py-2 bg-dark text-white">
                  <p class="mb-0 ">Información Asociada a la Alerta</p>
                </div>
                <CDataTable
                  v-show="!loading && alerta.info.length > 0"
                  hover
                  striped
                  :items="alerta.info"
                  :fields="fields"
                  index-column
                >
                  <template #user="data">
                    <td>{{data.item.user.name}} <small>({{data.item.user.email}})</small></td>
                  </template>
                  <template #type="data">
                    <td><CBadge :color="getBadge(data.item.type)">{{getEstado(data.item.type)}}</CBadge></td>
                  </template>
                </CDataTable>
                <div class="text-center font-weight-bold" v-show="!loading && alerta.info.length === 0">Aún no hay datos para mostrar</div>
              </CCol>

              <CCol col="12" sm="6">
                <div class="d-flex justify-content-center align-items-center font-weight-bold text-center my-3 py-2 bg-dark text-white">
                  <p class="mb-0 ">Información Asociada a la Atención</p>
                </div>
                
                <div>
                  <p style="display:flex; justify-content: flex-start;"><span style="font-weight: 500; margin-right: 6px;">Fecha de Atención: </span> {{alerta?.attention?.start}}</p>
                  <p style="display:flex; justify-content: flex-start;" v-if="alerta?.attention?.start && alerta?.attention?.end"><span style="font-weight: 500; margin-right: 6px;">Duración de Atención: </span> {{attention_duration}}</p>
                  <CDataTable
                  v-if="!loading && alerta?.attention"
                  v-show="!loading && alerta.info.length > 0"
                  hover
                  striped
                  :items="[alerta?.attention?.caller]"
                  :fields="[{key: 'name', label: 'Operador'},{key: 'email', label: 'Email'}]"
                  index-column>
                </CDataTable>

                <div v-if="alerta?.attention?.videorecording?.length">
                  <p class="d-flex justify-content-center font-weight-bold mt-2">Grabación de Atención</p>
                  <carousel paginationActiveColor="#03fc6b" paginationColor="#000" :adjustableHeight="true" :per-page="1" style="width: 500px;margin-top: 8px; margin: 0 auto; margin-top: 16px;">
                    <slide v-for="video in alerta?.attention?.videorecording" :key="video.id" style="display:flex;">
                      <video
                      width="100%"
                      :src="video?.recording_link"
                      controls
                    ></video>
                    </slide>
                  </carousel>
                </div>
                </div>
                <div class="text-center font-weight-bold" v-show="!loading && !alerta?.attention">Aún no hay datos para mostrar</div>
              </CCol>
            </CRow>
          </CCardBody>
        </CCard>
      </transition>
    </CCol>
  </CRow>
</template>

<script>
import { getAlert } from '../../api/alerts'
import moment from 'moment'
import { Carousel, Slide } from 'vue-carousel';


export default {
  name: 'Alert',
  components: { Carousel, Slide },
  data: () => {
    return {
      loading: true,
      alerta: null,
      fields: [
        {key: 'user', label: 'Usuario asociado'},
        {key: 'type', label: 'Tipo'},
        {key: 'info', label: 'Descripción'},
        {key: 'created_at', label: 'Fecha', _style:"min-width: 120px;"}
      ]
    }
  },
  computed: {
    attention_duration() {
      return moment(moment(this.attention?.end).diff(moment(this.attention?.start))).format('mm:ss') 
    }
  },
  mounted(){
    this.getAlert(this.$route.params.id)
  },
  methods: {
    formatPosition(position){
      console.log("formatPosition: ", position)
      const geo = {
        lat: position.lat,
        lng: position.lng
      }
      return geo
    },
    closeModal(){
      this.showModal = false
      this.getUsers()
    },
    getAlert(id){
      const data = { id }
      const self = this
      this.loading = true
      getAlert(data)
        .then(function(resp){
          self.alerta = resp.data
          self.loading = false
        })
        .catch(function(error){
          console.log(error)
        })
    },
    getBadge (status) {
      const info = {
          0: 'success',
          1: 'primary',
          2: 'primary',
          3: 'warning',
          4: 'warning',
          5: 'warning',
          6: 'warning',
          7: 'warning'
      }
      return info[status]
    },
    getEstado (status) {
      const info = {
          0: 'Cerrada en conformidad',
          1: 'Cerrada por inactividad',
          2: 'Cerrada por nula comunicación',
          3: 'Reabierta',
          4: 'Envío de ayuda a dirección',
          5: 'Comienzo alarmas en establecimiento',
          6: 'Comienzo Monitoreo',
          7: 'Término Monitoreo'
      }
      return info[status]
    }
  },
  filter(date) {
    return moment(date)
  }
}
</script>
<!-- 

  Tipos de info alerta (por parte de administración)

  0: Cerrada en conformidad
  1: Cerrada por inactividad
  2: Cerrada por nula comunicación
  3: Reabierta
  4: Envío de ayuda a dirección
  5: Comienzo alarmas en establecimiento
  6: Comienzo Monitoreo
  7: Término Monitoreo

-->